<template>
  <v-row>
    <v-col>
      <div v-html="advice"></div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { equalityArray } from '@/utils/checkers';
import get from 'lodash.get';

export default {
  name: 'PathDependent',
  mounted() {
    this.setResult(this.advice);
  },
  computed: {
    ...mapGetters('testMicro', [
      'id',
      'activeStep',
      'choiceJourney',
      'formSteps',
    ]),
    choiceJourneyBase() {
      return this.choiceJourney.slice(0, -1);
    },
    choiceJourneyLast() {
      return this.choiceJourney.slice(-1).pop();
    },
    advice() {
      return `${this.adviceBase }<br>${ this.adviceLast}`;
    },
    adviceBase() {
      const { advices } = this.formSteps[this.activeStep];
      return this.getItem(advices);
    },
    adviceLast() {
      return this.lastParagraph[this.choiceJourneyLast];
    },
    allLastParagraphs() {
      return this.$t(`${this.id}.adviceLastParagraph`);
    },
    lastParagraph() {
      // Special Case - Path 1 - Partner doesn't want child - without Partner says YES
      if (this.choiceJourney[0] === '0') {
        return this.allLastParagraphs.tree1or85;
      }
      // Special Case - Path 8.5 - Rape - without Partner says YES
      if (this.specialRapeVariant) {
        return this.allLastParagraphs.tree1or85;
      }
      // Special Case - Path 2 - Partner says YES
      if (this.specialPartnerSaysYesVariant) {
        const path2FirstTwoSteps = this.choiceJourney.slice(1, 3);
        if (equalityArray(path2FirstTwoSteps, ['0', '0'])) {
          return this.allLastParagraphs.tree211;
        }
        if (equalityArray(path2FirstTwoSteps, ['0', '1'])) {
          return this.allLastParagraphs.tree212;
        }
        if (equalityArray(path2FirstTwoSteps.slice(0, -1), ['2'])) {
          return this.allLastParagraphs.tree23;
        }
      }
      // Normal Case
      return this.allLastParagraphs.general;
    },
    specialPartnerSaysYesVariant() {
      const path2 = this.choiceJourney[0] === '1';
      const activeStep3 = this.activeStep === 3;
      const correctChoice2 = ['0', '2'].includes(this.choiceJourney[1]);
      const correctChoice3 = ['0', '1'].includes(this.choiceJourney[2]);
      return path2 && activeStep3 && correctChoice2 && correctChoice3;
    },
    specialRapeVariant() {
      const firstTwoSteps = this.choiceJourney.slice(0, 2);
      const rapeCase = equalityArray(firstTwoSteps, ['7', '4']);
      const activeStep2 = this.activeStep === 2;
      return rapeCase && activeStep2;
    },
  },
  methods: {
    ...mapActions('testMicro', [
      'setResult',
    ]),
    // TODO: Can be more refined
    getItem(item) {
      return this.activeStep === 0 ? item : get(item, this.choiceJourneyBase);
    },
  },
};
</script>
